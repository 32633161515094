.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.header {
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  left: 240px;
  /* position: fixed;
  right: 0;
  top: 0;
  z-index: 1001; */
  height: 65px;
}

.App-link {
  color: #61dafb;
}
.ql-editor {
  min-height: 200px;
}

.footer {
  background-color: #868889;
  color: #ffffff;
  margin: 0;

}

.footer a {
  color: #ffffff;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.footer .social-icons a {
  font-size: 1.5rem;
  margin-right: 1rem;
}

.footer .social-icons a:hover {
  color: #007bff;
}
.navbar-toggler {
  padding: 0.25rem 0.5rem; /* Adjust padding as needed */
  font-size: 0.575rem; /* Adjust font size as needed */
}
.dropdown-select {
  position: relative;
  cursor: pointer;
}

.dropdown-select .dropdown-icon {
  position: absolute;
  top: 50%;
  right: 2rem; /* Position from the right edge */
  transform: translateY(-50%);
  color: #6c757d; /* Adjust color as needed */
}
.user-circle {
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: grey;
  color: white;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  line-height: 35px; /* Vertically center the letter */
}

/* Hide the down arrow for the dropdown */
#basic-nav-dropdown .dropdown-toggle::after {
  content: none !important;
}

@media (min-width: 768px) {
  .userBtn {
  width: 400;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
